import { useState, useEffect } from "react"
import { useI18n } from "../i18n/i18n"
import { IStyleWrapperProps } from "../interfaces/props/IStyleWrapperProps"
import Header from "./header"

const StyleWrapper = ({theme, children}: IStyleWrapperProps) => {
  const {t} = useI18n()
  const [themeName, setThemeName] = useState<string|undefined>(theme)

  useEffect(() => {
    if(!themeName) {
      setThemeName(AppSettings.Theme)
    }
  }, [themeName, t])

  return(
    themeName ?
    <div className="app">
        <link rel="stylesheet" type="text/css" href={`./themes/${themeName}/styles/theme_styles.css`}></link>
        <Header/>
        {children}
    </div> : 
    <></>
  )
}

export default StyleWrapper
import { useState,useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from "react-router-dom";
import StyleWrapper from '../components/StyleWrapper';
import { ISearchParams } from '../interfaces/ISearchParams';
import AssessmentForm from '../components/AssessmentForm';
import Footer from '../components/footer';
import IBaseConfigWi18nProps from '../interfaces/props/base/IBaseConfigWi18nProps';
import { AssessmentFormErrorService } from "../services/errorService";
import { IParamHasMatch } from '../interfaces/IParamHasMatch';
import { SelectOption } from '../interfaces/SelectOption';
import Loading from '../components/Loading';
import {ValidateAccessCode} from "../services/validateAccessCodeService";

export default function Main({t, config}: IBaseConfigWi18nProps) {
  const {featureConfigurations: {useDefaultYOB, defaultYOB}, refuseInvalidFieldParamLink, studies} = config;
  const [searchParamsObj, setSearchParamsObj] = useState<ISearchParams>({});
  const [searchParams] = useSearchParams();
  const [validParams, setValidParams] = useState(false);
  const [validRedirectParams,setValidRedirectParams] = useState(false);

  const [redirectError, setRedirectError] = useState<string>();
  const [redirectSuccess, setRedirectSuccess] = useState<string>();

  const [accessCode, setAccessCode] = useState<string>("");
  const [accessCodeErrorTextKey, setAccessCodeErrorTextKey] = useState<string>("");

  const [yob, setYob] = useState<string>("");
  const [yobErrorTextKey, setYobErrorTextKey] = useState<string>("");

  const [gender, setGender] = useState<string>("");
  const [genderErrorTextKey, setGenderErrorTextKey] = useState<string>("");
  const [accessCodeValid, setAccessCodeValid] = useState<boolean>(true);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const genderSelectOptions: SelectOption[] = useMemo(() => [
    {name: t("TEXT_MALE"), value: "M"},
    {name: t("TEXT_FEMALE"), value: "F"}
  ], [t])

  const [study, setStudy] = useState<string>("");
  const [studyErrorTextKey, setStudyErrorTextKey] = useState<string>("");

  const [visitSession, setVisitSession] = useState<string>("");
  const [visitSessionErrorTextKey, setVisitSessionErrorTextKey] = useState<string>("");

  const [cacheUserInfo, setCacheUserInfo] = useState(false)

  const [paramHasMatch, setParamHasMatch] = useState<IParamHasMatch>({});
  
  const handleParamsTriggeredRef = useRef(false);
  const validateParamsTriggeredRef = useRef(false);


  const assessmentFormErrorService = AssessmentFormErrorService({
    searchParams: searchParamsObj,
    refuseInvalidFieldParamLink: config.refuseInvalidFieldParamLink,
    accessCodeSettings: { value: accessCode, setError: setAccessCodeErrorTextKey },
    yobSettings: { value: yob, setError: setYobErrorTextKey },
    genderSettings: { value: gender, setError: setGenderErrorTextKey },
    sessionSettings: { value: visitSession, setError: setVisitSessionErrorTextKey },
    studySettings: { value: study, setError: setStudyErrorTextKey },
    paramHasMatch
  });

  const {validate, validateParams, validateAsParam, errorMap} = assessmentFormErrorService;

  useEffect(()=> {
    let searchParamsEntries: ISearchParams = Object.fromEntries([...searchParams]);

    if(searchParamsEntries&&searchParamsEntries.redirectError&&searchParamsEntries.redirectSuccess){
      setRedirectError(searchParamsEntries.redirectError)
      setRedirectSuccess(searchParamsEntries.redirectSuccess)
    }

    if(Object.keys(searchParamsEntries).length === 0) {
      searchParamsEntries.paramsEmpty = true;
    }
    else if (searchParamsEntries.gender) {
      searchParamsEntries.gender = searchParamsEntries.gender.toUpperCase();
    }

    setSearchParamsObj(searchParamsEntries)
  }, [searchParams])

  useEffect(()=>{
    let configStudy = studies?.find(element => element.protocolName === study)

    if(configStudy) {
      let selectedVisitSession = configStudy.visitSessions.find(element => element.sessionCode === visitSession);
      if(selectedVisitSession) {
        setCacheUserInfo(selectedVisitSession.cacheUserInfo)
      }
    }
  },[visitSession])

  const paramLoaded = (value: string, param?: string, paramHasMatch?: boolean) => {
    return (param === undefined) || (param && (value !== "" || paramHasMatch === false))
  }
  // handle search params
  useEffect(() => {
    if(!handleParamsTriggeredRef.current && studies) {
      const newParamHasMatch: IParamHasMatch = {
        gender: false, 
        study: false,
        session: false,
        accessCode: false,
        yob: false
      };
      const {accessCode, yob, gender, study, session} = searchParamsObj;

      if (accessCode) {
        setAccessCode(accessCode);
        newParamHasMatch.accessCode = true;
        (async () =>
        {
          setShowLoading(true)
          let validationResult = await ValidateAccessCode(accessCode)
          setAccessCodeValid(validationResult);
          setShowLoading(false)
        })();
      }

      const yobDefault = useDefaultYOB ? defaultYOB : yob;
      if(yobDefault) {
        errorMap.yob.value = yobDefault;
        let valid = validateAsParam(errorMap.yob, true)
        if(valid) {
          setYob(yobDefault);
          newParamHasMatch.yob = true;
        }
      }

      if(gender) {
        if(genderSelectOptions.find(element => element.value === gender)) {
          setGender(gender);
          newParamHasMatch.gender = true;
        }
      }
  
      let configStudy = studies?.find(element => element.protocolName === study)
      if(study) {
        if(configStudy) {
          setStudy(configStudy.protocolName);
          newParamHasMatch.study = true;
        }
      }
  
      if(session){
        if(configStudy) {
          let selectedVisitSession = configStudy.visitSessions.find(element => element.sessionCode === session);
          if(selectedVisitSession) {
            setVisitSession(selectedVisitSession.sessionCode);
            newParamHasMatch.session = true;
          }
        }
      }

      setParamHasMatch(newParamHasMatch);

      handleParamsTriggeredRef.current = true;
    }
    
  }, [searchParamsObj, studies, paramHasMatch, genderSelectOptions, setGender, setStudy, setVisitSession, defaultYOB, useDefaultYOB])

  const validateRedirectParams =()=>{
    if(config.redirectUrlConfig.redirectUrlType==="QueryStringParameters"&&!(redirectError && redirectSuccess)){
      return false
    }
    return true
  }

  // Validate params if necessary, once only on load
  useEffect(() => {
    if
    (
      !validateParamsTriggeredRef.current && handleParamsTriggeredRef.current && refuseInvalidFieldParamLink
      && paramLoaded(accessCode, searchParamsObj.accessCode) && paramLoaded(visitSession, searchParamsObj.session, paramHasMatch.session) && paramLoaded(yob, searchParamsObj.yob) 
      && paramLoaded(study, searchParamsObj.study, paramHasMatch.study) && paramLoaded(gender, searchParamsObj.gender, paramHasMatch.gender) 
    )
    {
      validateParamsTriggeredRef.current = true;
      setValidParams(validateParams());
    }
    setValidRedirectParams(validateRedirectParams)

  }, [validateParams, refuseInvalidFieldParamLink, study, accessCode, visitSession, gender, yob, paramHasMatch, searchParamsObj])


  const setTextDangerouslyIfCustomTitle = () =>{
    if(config.landingPageConfig.landingPageTitleCustomEnabled && config.landingPageConfig.landingPageTitleCustomContent){
      return <div dangerouslySetInnerHTML={{__html:config.landingPageConfig.landingPageTitleCustomContent}}/>
    }else{
      return (<div>
                <h3 className='menu--text-header'>{t("TEXT_BEGIN_YOUR_COGNITIVE_ASSESSMENT")}</h3>
              </div>)
    }
  }

  let pageReady = handleParamsTriggeredRef.current && !showLoading
  return (
    <StyleWrapper>
      { pageReady ?
        ( (!(refuseInvalidFieldParamLink && !validParams) && accessCodeValid && validRedirectParams) ?
          <>
            <div className="menu--background-image">
              <div className='menu--container'>
                {setTextDangerouslyIfCustomTitle()}
                <AssessmentForm searchParams={searchParamsObj} config={config} t={t} 
                  accessCodeSettings={[accessCode, setAccessCode, accessCodeErrorTextKey]}
                  yobSettings={[yob, setYob, yobErrorTextKey]}
                  genderSettings = {[gender, setGender, genderErrorTextKey, genderSelectOptions]}
                  studySettings = {[study, setStudy, studyErrorTextKey]}
                  sessionSettings = {[visitSession, setVisitSession, visitSessionErrorTextKey]}
                  assessmentFormErrorService = {assessmentFormErrorService}
                  paramHasMatch={paramHasMatch}
                  redirectSuccess={redirectSuccess}
                  redirectError={redirectError}
                  cacheUserInfo={cacheUserInfo}
                /> 
              </div>
            </div>
            <Footer t={t} config={config}/>
          </>
          :
          <div className="menu--background-image">
            <div className='menu--container-full-width'>
              <h3 className='menu--text-header'>{t("ERROR_LINK_INVALID")}</h3>
              <p className='menu--text'>{t("ERROR_HAS_OCCURED_CONTACT_ADMINISTRATOR")}</p>
            </div>
          </div>
        )
        :
        <Loading t={t} descTextKey='TEXT_LOADING'/>
      }
    </StyleWrapper>
  );
}
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import i18next from "i18next";

let currentLocale = AppSettings.DefaultLanguage
let ns = AppSettings.LocaleNS
    i18next
        .use(Backend)
        .use(initReactI18next)
        .init({
            load: 'currentOnly',
            debug: true,
            fallbackLng: 'en-US',
            lng: currentLocale,
            preload: [currentLocale],
            ns: [ns],
            backend: {
                loadPath: `/locales/{{lng}}/${ns}.json`,
            },
            interpolation: {escapeValue: false},
        });

import {t} from "../i18n/i18n";

export function GenerateAccessCodes(accessToken: string, generateAmount:number) {
    fetch(`${AppSettings.ApiEndpointUrl}/CreateAccessCodes/${generateAmount}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    })
    .then(
        (response) => {
            if (!response.ok) {
                throw new Error();
            }
            return response.json()
        }
    )
    .then(
        (result) => {
            const today = new Date();
            var dd = String(today.getDate());
            var mm = String(today.getMonth() + 1);
            if(mm.length===1){mm="0"+mm}
            if(dd.length===1){dd="0"+dd}
            const yy = today.getFullYear().toString();
            const date = `${yy}-${mm}-${dd}`
            const url = window.location.href.split("/").slice(0,-1).join("/")

            result.forEach((code:any, index:any) => {
                result[index] = `${code},${url}?accessCode=${code}`;
            });

            const headers = ["Access code","Url with access code"]
            result.unshift(headers.join(","))

            const csvType = "data:text/csv;charset=utf-8"
            const csvContent = csvType +","+result.join('\n')
            const encodedUri = encodeURI(csvContent);

            const hiddenElement = document.createElement('a');
            hiddenElement.href =  encodedUri;
            hiddenElement.target = '_blank';
            hiddenElement.download = `CogstateAccessCodes-${date}.csv`;
            hiddenElement.click();
        }
    )
    .catch(
        () => {
            alert(t("ERROR_RETRIEVING_ACCESS_CODE"));
        }
    )
}
import React from 'react';
import {useI18n} from "../i18n/i18n";
import { ITextInputProps } from '../interfaces/props/ITextInputProps';

export default function TextInput({placeholderTextKey, errorTextKey, value, change, hideFeatureFlag=false, defaultValue=""}: ITextInputProps) {
    const {t} = useI18n()

    if(hideFeatureFlag === true  && defaultValue !== "")
        value = defaultValue

    return (
        <div className='menu--item-container' hidden={hideFeatureFlag === true}>
            <input className='menu--item' value={value} type="text" placeholder={t(placeholderTextKey)} onChange={(e)=>{change(e.target.value)}}/>
            <div className='menu--error-notif'>
                {t(errorTextKey)}
            </div>
        </div>
        
    );
}
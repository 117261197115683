import React from 'react';
import {useI18n} from "../i18n/i18n";

export default function CodesFooter() {
    const {t} = useI18n()

    return (
        <div className='footer'>
            <section  className='footer--expectation-panel'>
                <div className='footer--dark-text-header'>{t("TEXT_ACCESS_CODE_GENERATION")}</div>
                <div className='footer--dark-text'>{t("TEXT_UPON_CLICKING_GENERATE_ACCESS_CODES")}</div>
            </section>
        </div>
    );
}

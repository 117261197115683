import { IRedirectUrlConfig } from "../interfaces/IRedirectUrlConfig";

export function ValidateRedirectUrlConfig(redirectUrlConfig: IRedirectUrlConfig) {
    if (!redirectUrlConfig || !redirectUrlConfig.redirectUrlType){
        return false;
    }

    switch(redirectUrlConfig.redirectUrlType){
        case "Internal":
            return true;
        case "InternalCustomMessage":
            if (redirectUrlConfig.customSuccessTitle &&
                redirectUrlConfig.customErrorTitle &&
                redirectUrlConfig.customSuccessText && 
                redirectUrlConfig.customErrorText){
                    return true;
            }
            return false; 
        case "TemplateGenerated":
            if(redirectUrlConfig.templateErrorUrl &&
                redirectUrlConfig.templateSuccessUrl){
                return true;
            }
            return false;
        case "QueryStringParameters":
            return true;
        default:
            return false;
    }
    
}
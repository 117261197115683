import "./i18n/i18nextConfig";
import './styles/component_styles.css'
import { BrowserRouter, Route, Routes} from "react-router-dom";
import { GetConfig } from './services/configService';
import Main from './pages/Main';
import AssessmentCompletePage from './pages/assessmentcomplete';
import GenerateCodesPage from './pages/generateCodesPage';
import { useI18n } from "./i18n/i18n";
import { useEffect, useRef, useState } from "react";
import { defaultConfiguration, IConfiguration } from "./interfaces/IConfiguration";

export default function App() {
  const {t} = useI18n()
  const [configuration, setConfiguration] = useState<IConfiguration>(defaultConfiguration)
  const getConfigTriggeredRef = useRef(false);

  useEffect(() => {
    if(!getConfigTriggeredRef.current) {
      getConfigTriggeredRef.current = true;
      GetConfig()
      .then(data => setConfiguration(data))
    }
  })

  return (
    <BrowserRouter>
          <Routes>
              <Route path = "*" element = {<Main config={configuration} t={t}/>}></Route>
              <Route path = "/success" element = {<AssessmentCompletePage t={t} status={"success"} config={configuration}/>}></Route>
              <Route path = "/error" element = {<AssessmentCompletePage t={t} status={"error"} config={configuration}/>}></Route>
              <Route path = "/generatecodes" element = {<GenerateCodesPage config={configuration} t={t}/>}></Route>
          </Routes>
    </BrowserRouter>
  );
  
}
/**
     * Abstraction layer for a future localization library
     */
import {useTranslation} from "react-i18next";
import i18next, { StringMap, TOptions } from "i18next";

const filter = (key: string) => {
    return key === "";
}

export function useI18n() {
    const {t} = useTranslation();
    return {
        t: (key:string, options?: string | TOptions<StringMap>) => {
            if (filter(key)) {
                return key;
            }
            return t(key, options);
        }
    };
}

export const t = (key: string): string => {
    if (filter(key)) {
        return key;
    }
    return i18next.t(key);
}
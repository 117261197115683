import { useEffect, useState } from "react"
import { IAssessmentRequest } from "../interfaces/IAssessmentRequest"
import { IErrorMap } from "../interfaces/IErrorMap"
import { IAssessmentFormProps } from "../interfaces/props/IAssessmentFormProps"
import { SelectOption } from "../interfaces/SelectOption"
import { CreateAssessment } from "../services/createAssessmentService"
import Dropdown from "./Dropdown"
import Policies from "./policies"
import SubmitButton from "./SubmitButton"
import TextInput from "./TextInput"
import {IFeatureConfigurations} from "../interfaces/props/IFeatureConfigurations";
import IVisitSession from "../interfaces/IVisitSession"

const AssessmentForm = ({searchParams: sp, config, t, 
  accessCodeSettings: [accessCode, setAccessCode, accessCodeErrorTextKey], 
  yobSettings: [yob, setYob, yobErrorTextKey], 
  genderSettings: [gender, setGender, genderErrorTextKey, genderSelectOptions],
  studySettings: [study, setStudy, studyErrorTextKey], 
  sessionSettings: [visitSession, setVisitSession, visitSessionErrorTextKey],
  assessmentFormErrorService: {errorMap, validate, validateParams, validateAsParam},
  paramHasMatch,
  redirectSuccess,
  redirectError,
  cacheUserInfo
}: IAssessmentFormProps) => {

  const [visitSessionPlaceholderTextKey, setVisitSessionPlaceholderTextKey] = useState<string>("");
  const [visitSessionOptions, setVisitSessionOptions] = useState<Array<SelectOption>>([]);
  const [isWaitingForServer, setIsWaitingForServer] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [visitSessionsForStudy, setVisitSessionsForStudy] = useState<Array<IVisitSession>>([]);

  const {studies, refuseInvalidFieldParamLink} = config;

  const studyOptions: SelectOption[] = studies ? studies.map(
    studyInfo => ({name: studyInfo.name, value: studyInfo.protocolName})
  ) : [{name: "", value: ""}]

  const accessCodeKey = "accessCode"

  const UsingDefault : IFeatureConfigurations = config.featureConfigurations ||
      {useDefaultGender: false, defaultGender: "", useDefaultYOB: false, defaultYOB: ""}

  // Gender dropdown
  const genderSelectName = "select gender" ;

  const storeAccessCodeLocally=(accessCode:string)=>{
    localStorage[accessCodeKey] = accessCode
  }

  const getLocalAccessCode = ()=>{
    return localStorage[accessCodeKey]
  }

  const isAccessCodeStoredLocally=()=>{
    return localStorage.getItem(accessCodeKey)!==null
  }

  // hook to handle auto-select of visit session based on selected study
  useEffect(()=> {
    if(!(paramHasMatch.session)) {
      const selectedStudy = studies?.find(element => element.protocolName === study)
      if (selectedStudy && selectedStudy.visitSessions) {
        const visitSessionsForStudy = selectedStudy.visitSessions
        setVisitSessionsForStudy(visitSessionsForStudy)
      	setVisitSessionPlaceholderTextKey("LABEL_SELECT_VISIT")
      	if (visitSessionsForStudy.length > 1) {
          // multiple sessions returned, clear current selection
          setVisitSession("")
      	} else {
          // if there is one valid visit session, that session is automatically selected.
          setVisitSession(visitSessionsForStudy[0].sessionCode)
      	}
      	setVisitSessionOptions(visitSessionsForStudy.map((session)=>{return {name: session.name, value: session.sessionCode}}))
      }
      else {
        setVisitSessionPlaceholderTextKey("LABEL_SELECT_STUDY_FIRST")
        setVisitSessionOptions([])
        setVisitSession("")
      }
    }
  }, [study, studies, paramHasMatch, setVisitSession, setVisitSessionsForStudy])

  const submitForm = () => {
    let validForm = validate() 
    let validParams = true;

    if (refuseInvalidFieldParamLink && !sp.paramsEmpty) {
      validParams = validateParams();
    }

    const fullVisitSession = visitSessionsForStudy.find(element => element.sessionCode === visitSession)

    if (((validForm && validParams) || isAccessCodeStoredLocally())) {
      let mode=""
      if(fullVisitSession){
        mode = fullVisitSession.mode?fullVisitSession.mode:""
      }
      let data: IAssessmentRequest = {
        externalId: accessCode,
        yob: UsingDefault.useDefaultYOB === true && UsingDefault.defaultYOB !== "" ? UsingDefault.defaultYOB : yob,
        genderCode: gender,
        visitSessionCode: visitSession,
        localityCode: AppSettings.DefaultLanguage,
        redirectSuccess: redirectSuccess,
        redirectError: redirectError,
        mode: mode === "smartphone" ? mode : ""
      }

      if(isAccessCodeStoredLocally()){
        data={
          externalId:getLocalAccessCode(),
          localityCode:AppSettings.DefaultLanguage,
          mode: mode === "smartphone" ? mode : ""
        }
      }
      
      setIsWaitingForServer(true)
      
      if(cacheUserInfo){
        storeAccessCodeLocally(accessCode)
      }

      CreateAssessment(data,study===""?null:study).then((success)=>{
        if(!success) {
          setIsWaitingForServer(false)
        }
      })
    }
  }

  const showField = (errorMap: IErrorMap, param?: string, paramHasMatch?: boolean): boolean => {
    let hide = param && validateAsParam(errorMap, paramHasMatch)
    
    if (paramHasMatch){
      hide = hide && paramHasMatch
    }

    return !(hide); 
  }

  const startTestDisabled = isWaitingForServer || !termsAccepted;

  return (
    <>
      <div className='menu'>
        {
          showField(errorMap.gender, sp.gender, paramHasMatch.gender) && !isAccessCodeStoredLocally() &&
          <Dropdown 
            change={(newVal:string)=>{setGender(newVal)}}
            options={genderSelectOptions}
            selectName={genderSelectName}
            placeholderTextKey="LABEL_SELECT_GENDER"
            errorTextKey={genderErrorTextKey}
            value={gender}
            autoSelectSingleOption={false}
            hideFeatureFlag={UsingDefault.useDefaultGender}
            defaultValue={UsingDefault.defaultGender}
          />
        }
        {
          showField(errorMap.study, sp.study, paramHasMatch.study) && !isAccessCodeStoredLocally() &&
          <Dropdown 
            change={(newVal:string)=>{setStudy(newVal)}}
            options={studyOptions}
            selectName="study select"
            placeholderTextKey="LABEL_SELECT_STUDY"
            errorTextKey={studyErrorTextKey}
            value={study}
            autoSelectSingleOption={true}
          />
        }
        {
          showField(errorMap.accessCode, sp.accessCode) && !isAccessCodeStoredLocally() &&
          <TextInput 
            change={(newVal:string)=>{setAccessCode(newVal)}} 
            placeholderTextKey="LABEL_ACCESS_CODE_PLEASE_ENTER"
            errorTextKey={accessCodeErrorTextKey}
            value={accessCode}
          />
        }
        {
          showField(errorMap.yob, sp.yob, paramHasMatch.yob) && !isAccessCodeStoredLocally() &&
          <TextInput 
            change={(newVal:string)=>{setYob(newVal); paramHasMatch.yob = false}}
            placeholderTextKey="LABEL_YEAR_OF_BIRTH_PLEASE_ENTER"
            errorTextKey={yobErrorTextKey}
            value={yob}
            hideFeatureFlag={UsingDefault.useDefaultYOB}
            defaultValue={UsingDefault.defaultYOB}
          />
        }
        {
          showField(errorMap.session, sp.session, paramHasMatch.session) && !isAccessCodeStoredLocally() &&
          <Dropdown
            change={(newVal:string)=>{setVisitSession(newVal)}}
            options={visitSessionOptions}
            selectName="study select"
            placeholderTextKey={visitSessionPlaceholderTextKey}
            errorTextKey={visitSessionErrorTextKey}
            value={visitSession}
            autoSelectSingleOption={true}
          />
        }
        <Policies termsAccepted={termsAccepted} setTermsAccepted={setTermsAccepted} config={config} t={t} />
        <SubmitButton 
          className={startTestDisabled ? "submit-button--disabled":"submit-button"} 
          action={submitForm} 
          buttonTextKey="BUTTON_START_THE_TEST_UPPER_CASE" 
          disabled ={startTestDisabled}
        />
      </div>
    </>
  )
}

export default AssessmentForm
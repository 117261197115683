import {useI18n} from "../i18n/i18n";
import { ISubmitButtonProps } from '../interfaces/props/ISubmitButtonProps';

export default function SubmitButton({buttonTextKey, action, className, disabled }: ISubmitButtonProps) {
    const {t} = useI18n()

    return (
        <div className={"start-test-div"}>
            <button className={className} onClick={() => action()} disabled={disabled}>{t(buttonTextKey)}</button>
            <div className='menu--error-notif'/>
        </div>
    );
}
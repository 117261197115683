import { useI18n } from "../i18n/i18n"

const AzureADError = ({error}: any) => {
  const {t} = useI18n()
  
  return(
    <div className="menu--background-image">
      <div className='menu--container-full-width'>
        <h3 className='menu--text-header'>{t("ERROR_OCCURRED_DURING_AUTHENTICATION")}</h3>
        <p className='menu--text'>{error}</p>
      </div>
    </div>
  )
}

export default AzureADError